import React from 'react';
import '@splidejs/splide/dist/css/splide.min.css';
import GlobalStyles from './styles/GlobalStyles';
import Header from './components/Header';
import Hero from './components/Hero';
import FeaturedArtworks from './components/FeaturedArtworks';
import Blog from './components/Blog';
import Footer from './components/Footer';

function App() {
  return (
    <>
      <GlobalStyles />
      <Header />
      <Hero />
      <FeaturedArtworks />
      <Blog />
      <Footer />
    </>
  );
}

export default App;