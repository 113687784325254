import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  background: var(--background-gradient);
  color: var(--text-color);
  padding: 4rem 2rem;
`;

const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
`;

const FooterSection = styled.div`
  flex: 1;
  min-width: 200px;
`;

const FooterLogo = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 1rem;
`;

const FooterLinks = styled.ul`
  list-style: none;
  padding: 0;
`;

const FooterLink = styled.li`
  margin-bottom: 0.5rem;
`;

const FooterLinkAnchor = styled.a`
  color: white;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const FooterCopyright = styled.p`
  margin-top: 2rem;
  text-align: center;
  width: 100%;
`;

const Footer = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <FooterSection>
          <FooterLogo>Vauted</FooterLogo>
          <p>Revolutionizing art investment through blockchain technology.</p>
        </FooterSection>
        <FooterSection>
          <h3>Quick Links</h3>
          <FooterLinks>
            <FooterLink><FooterLinkAnchor href="#featured">Featured Artworks</FooterLinkAnchor></FooterLink>
            <FooterLink><FooterLinkAnchor href="#how-it-works">How It Works</FooterLinkAnchor></FooterLink>
            <FooterLink><FooterLinkAnchor href="#">About Us</FooterLinkAnchor></FooterLink>
          </FooterLinks>
        </FooterSection>
        <FooterSection>
          <h3>Legal</h3>
          <FooterLinks>
            <FooterLink><FooterLinkAnchor href="#">Privacy Policy</FooterLinkAnchor></FooterLink>
            <FooterLink><FooterLinkAnchor href="#">Terms of Service</FooterLinkAnchor></FooterLink>
          </FooterLinks>
        </FooterSection>
      </FooterContent>
      <FooterCopyright>
        © {new Date().getFullYear()} Vauted. All rights reserved.
      </FooterCopyright>
    </FooterContainer>
  );
};

export default Footer;