import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  :root {
    --primary-color: #6E00FF;
    --secondary-color: #9D00FF;
    --background-gradient: linear-gradient(135deg, #1E0E2F 0%, #0A1A2F 100%);
    --text-color: #FFFFFF;
    --subtext-color: #B3B3B3;
  }

  body {
    font-family: 'Inter', sans-serif;
    color: var(--text-color);
    background: var(--background-gradient);
    margin: 0;
    padding: 0;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
  }
`;

export default GlobalStyles;