import React from 'react';
import styled from 'styled-components';

const BlogContainer = styled.section`
  padding: 4rem 2rem;
  background: var(--background-gradient);
`;

const BlogTitle = styled.h2`
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 3rem;
`;

const BlogGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
`;

const BlogPost = styled.div`
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.3s ease;
  &:hover {
    transform: translateY(-5px);
  }
`;

const BlogImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
`;

const BlogContent = styled.div`
  padding: 1.5rem;
`;

const BlogPostTitle = styled.h3`
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
`;

const BlogExcerpt = styled.p`
  font-size: 1rem;
  color: var(--subtext-color);
`;

const Blog = () => {
  const blogPosts = [
    { id: 1, title: "The Rise of Tokenized Art", excerpt: "How blockchain is changing the art market...", image: "url-to-blog-image-1" },
    { id: 2, title: "Investing in Digital Art", excerpt: "A guide to understanding and valuing digital artworks...", image: "url-to-blog-image-2" },
    { id: 3, title: "Artist Spotlight: Digital Pioneers", excerpt: "Meet the artists pushing the boundaries of digital art...", image: "url-to-blog-image-3" },
  ];

  return (
    <BlogContainer>
      <BlogTitle>Latest from Our Blog</BlogTitle>
      <BlogGrid>
        {blogPosts.map((post) => (
          <BlogPost key={post.id}>
            <BlogImage src={post.image} alt={post.title} />
            <BlogContent>
              <BlogPostTitle>{post.title}</BlogPostTitle>
              <BlogExcerpt>{post.excerpt}</BlogExcerpt>
            </BlogContent>
          </BlogPost>
        ))}
      </BlogGrid>
    </BlogContainer>
  );
};

export default Blog;