import React from 'react';
import LazyLoad from 'react-lazyload';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';

const FeaturedArtworks = () => {
  const artworks = [
    { id: 1, image: "https://utfs.io/f/56684ea6-6772-4b51-b348-8dc8af21662b-brtmlp.jpg" },
    { id: 2, image: "https://utfs.io/f/96d64a2a-806d-4336-a274-077fc36e5af3-k2k259.png" },
    { id: 3, image: "https://utfs.io/f/d958c19d-5f67-4de9-8ad7-31250c5cb81a-f95ymv.jpg" },
    { id: 4, image: "https://utfs.io/f/2cd807b1-455e-460e-8396-b37e05aeb9c3-gda66f.jpg" },
  ];

  const splideOptions = {
    type: 'loop',
    perPage: 3,
    perMove: 1,
    gap: '0.5rem',
    autoplay: true,
    pauseOnHover: false,
    resetProgress: false,
    height: '400px',
    speed: 1000,
    autoplaySpeed: 3000,
    breakpoints: {
      1024: {
        perPage: 2,
      },
      640: {
        perPage: 1,
      },
    },
  };

  return (
    <Splide options={splideOptions}>
      {artworks.map(artwork => (
        <SplideSlide key={artwork.id}>
          <LazyLoad height={400}>
            <img
              src={artwork.image}
              alt={`Artwork ${artwork.id}`}
              style={{ width: '100%', height: '400px', objectFit: 'cover', cursor: 'pointer' }}
            />
          </LazyLoad>
        </SplideSlide>
      ))}
    </Splide>
  );
};

export default FeaturedArtworks;