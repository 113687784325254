import React, { useState } from 'react';
import { motion } from 'framer-motion';
import ArrowIcon from '../icons/ArrowIcon';
import styled from 'styled-components';

const HeroContainer = styled(motion.section)`
  padding: 8rem 2rem;
  min-height: 100vh;
  display: flex;
  align-items: center;
`;

const HeroContent = styled(motion.div)`
  max-width: 800px;
  text-align: left;
`;

const HeroTitle = styled.h1`
  font-size: 4.5rem;
  margin-bottom: 1rem;
  line-height: 1.2;
`;

const HeroSubtitle = styled.p`
  font-size: 1.5rem;
  margin-bottom: 2rem;
  color: var(--subtext-color);
`;

const CTAButton = styled(motion.button)`
  display: flex;
  align-items: center;
  padding: 1rem 2rem;
  font-size: 1.25rem;
  background-color: var(--primary-color);
  color: white;
  border: none;
  cursor: pointer;
  outline: none;
  border-radius: 8px;
  overflow: hidden; /* Ensure the button doesn't overflow */
  
  &:hover {
    background-color: var(--secondary-color);
  }
`;

const ArrowWrapper = styled(motion.div)`
  margin-left: 0.5rem;
`;

const Hero = () => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <HeroContainer>
      <HeroContent>
        <HeroTitle>Revolutionize Your Art Investments</HeroTitle>
        <HeroSubtitle>Tokenize, trade, and own fractions of high-value artworks</HeroSubtitle>
        <CTAButton
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          initial={{ paddingRight: '2rem' }}
          animate={{ paddingRight: isHovered ? '4rem' : '2rem' }}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          Get Started
          <ArrowWrapper
            initial={{ opacity: 0, x: -10 }}
            animate={{ opacity: isHovered ? 1 : 0, x: isHovered ? 0 : -10 }}
          >
            <ArrowIcon width={18} height={18} />
          </ArrowWrapper>
        </CTAButton>
      </HeroContent>
    </HeroContainer>
  );
};

export default Hero;