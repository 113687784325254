import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import ArrowIcon from '../icons/ArrowIcon';

const HeaderContainer = styled(motion.header)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  background: ${props => props.isScrolled ? 'rgba(30, 14, 47, 0.9)' : 'transparent'};
  backdrop-filter: ${props => props.isScrolled ? 'blur(10px)' : 'none'};
  transition: all 0.3s ease;
`;

const Logo = styled.h1`
  font-size: 1.5rem;
  color: var(--text-color);
`;

const Nav = styled(motion.nav)`
  display: flex;
  align-items: center;
  gap: 2rem;
`;

const NavLink = styled(motion.a)`
  text-decoration: none;
  color: var(--text-color);
  font-weight: 500;
  &:hover {
    color: var(--secondary-color);
  }
`;

const CTAButton = styled(motion.button)`
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  background-color: var(--primary-color);
  color: white;
  border: none;
  cursor: pointer;
  outline: none;
  border-radius: 4px;
  overflow: hidden;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: var(--secondary-color);
  }
`;

const ArrowWrapper = styled(motion.div)`
  margin-left: 0.5rem;
`;

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <HeaderContainer isScrolled={isScrolled}>
      <Logo>Vauted</Logo>
      <Nav>
        <NavLink
          href="#featured"
          animate={{ x: isHovered ? -20 : 0 }}
        >
          Featured
        </NavLink>
        <NavLink
          href="#how-it-works"
          animate={{ x: isHovered ? -20 : 0 }}
        >
          How It Works
        </NavLink>
        <CTAButton
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          initial={{ paddingRight: '1rem' }}
          animate={{ paddingRight: isHovered ? '2.5rem' : '1rem' }}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          Get Started
          <ArrowWrapper
            initial={{ opacity: 0, x: -10 }}
            animate={{ opacity: isHovered ? 1 : 0, x: isHovered ? 0 : -10 }}
          >
            <ArrowIcon width={18} height={18} />
          </ArrowWrapper>
        </CTAButton>
      </Nav>
    </HeaderContainer>
  );
};

export default Header;